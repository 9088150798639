<template>
  <div style="min-height: calc(100vh - 70px);background-color: rgb(255, 255, 255);">
    <div class="carousel-login">
      <Flicking
        :options="{ align: 'prev', circular: true }"
        class="swiper"
        :plugins="plugins"
      >
        <div
          v-for="banner in bannerList"
          :key="banner.id"
          :style="{
            backgroundImage:
              'url(' + 'https://tot.xlteacher.cn' + banner.pic + ')',
          }"
        ></div>
        <div slot="viewport" class="flicking-pagination"></div>
      </Flicking>

      <!-- DONE:根据登录判断显示 -->
    </div>
    <div  class="category-recommend"  style="height: 375px;background: #FFFFFF;">
      <div class="category-course" style="width: 1200px; margin: auto;padding-top: 35px;padding-left: 13px;display: flex">
        <div>
          <div style="display: flex;">
              <div  data-tid="1" :class="selected === 'button1' ? 'active' : ''" @click="select('button1')">
                <span class="course-title" data-tid="1"  @click="changeList">行业要闻&nbsp;</span>|
              </div>
              <div  data-tid="2" :class="selected === 'button2' ? 'active' : ''" @click="select('button2')" style="padding-left: 5px;">
                <span class="course-title" data-tid="2"  @click="changeList">通知公告</span>
              </div>
              <el-link :underline="false" @click="$router.push(`/morenew?type=${selected}`)" style="font-family: PingFang SC;font-weight: 400;font-size: 16px;color: #666666;line-height: 0px;padding-left: 457px;">更多>></el-link>
          </div>
          
          <p style="width: 725px;height: 0px;border: 1px solid #707070;opacity: 0.15;"></p>
          <ul style="width: 703px;height: 276px;">
            <li v-for="news in newsList" :key="news.id" @click="$router.push(`/details?nid=${news.id}&tid=${tid}`)">
              <span class="title">{{news.title}}</span>
              <span class="time">{{news.newsdate}}</span>
            </li>
          </ul>
        </div>
        <div style="padding-left: 38px;padding-top: 38px;">
          <a href="https://mp.weixin.qq.com/s/gpUKaQAkCYslCZ3lWjlUAw" target="_blank">
           <img src="../assets/images/test.png" width="420px" height="253px">
          </a>
        </div>
        
        <!-- <div class="category-box">
          <div
            v-for="type in courseType"
            :key="type.id"
            class="course-item"
            :style="{
              background:
                'url(' + 'https://tot.xlteacher.cn' + type.pic + ') no-repeat',
            }"
            @click="
              $router.push(`/courlist?tid=${type.id}&tn=${type.type_name}`)
            "
          >
            <img :src="`https://tot.xlteacher.cn${type.pic}`" />
            <b>{{ type.type_name }}</b>
          </div>
        </div> -->
      </div>
    </div>
    <div class="category-recommend" style=" height: 613px;background: #F6F8FD;border-radius: 0px;padding-bottom: 12px;">
      <!-- <div class="category-course">
        <span class="course-title">课程类型</span>
        <el-link :underline="false" @click="$router.push('/courtype')"
          >更多</el-link
        >
        <div class="category-box">
          <div
            v-for="type in courseType"
            :key="type.id"
            class="course-item"
            :style="{
              background:
                'url(' + 'https://tot.xlteacher.cn' + type.pic + ') no-repeat',
            }"
            @click="
              $router.push(`/courlist?tid=${type.id}&tn=${type.type_name}`)
            "
          >
            <img :src="`https://tot.xlteacher.cn${type.pic}`" />
            <b>{{ type.type_name }}</b>
          </div>
        </div>
      </div> -->
      <div class="recommend-course" style="width: 1200px; margin: auto;padding-top: 35px;padding-left: 13px;">
        <span  style="font-family: PingFang SC;font-weight: 600;font-size: 22px;color: #333333;line-height: 0px;padding-bottom: 15px">推荐课程</span>
        <el-link :underline="false" @click="$router.push(`/courlist?tid=65&tn=培训班课程`)" style="font-family: PingFang SC;font-weight: 400;font-size: 16px;color: #666666;line-height: 0px;padding-right: 24px;
    padding-top: 9px;"
          >更多>></el-link
        >
        <div class="recommend-box">
          <div
            class="recommend-item"
            @click="goCourseDetail(recommend)"
            v-for="recommend in courseRecommend"
            :key="recommend.id"
          >
            <div class="recommend-item-img">
              <img
                :src="'https://tot.xlteacher.cn' + recommend.pic"
                :alt="recommend.title" 
              />
            </div>
            <p class="recommend-item-text">
              <span class="text-title">{{ recommend.title }}</span>
              <!-- <span class="text-time">{{ recommend.video_time }}课时</span> -->
            </p>
          </div>
        </div>
      </div>
    </div>
    
    <!-- <div class="news-infos">
      <div class="news-wrapper">
        <span class="course-title">新闻资讯</span>
        <News :newsList="newsList.list" v-if="newsList.count !== 0" />
        <el-pagination
          v-if="!newsList.count === 0"
          :current-page="currentPage"
          layout="prev,pager,next"
          :total="newsList.count"
          :page-size="10"
          background
          prev-text="上一页"
          next-text="下一页"
          @current-change="pageChange"
          hide-on-single-page
        >
        </el-pagination>
        <div
          class="news-details"
          v-if="newsList.count === 0"
          style="
            text-align: center;
            font-size: 18px;
            margin-top: 20px;
            margin-bottom: 20px;
          "
        >
          暂无新闻列表
        </div>
      </div>
    </div> -->
    <div style="width: 1200px;margin: auto;padding-bottom: 30px;padding-top: 32px;cursor: pointer;" @click="$router.push(`/courlist?tid=65&tn=培训班课程`)">
      <img src="../assets/images/ad.png">
    </div>
    <div class="teacher-intro" style="padding-bottom: 30px;">
      <div style="width: 1200px; margin: auto;padding-bottom: 9px;">
        <span style="font-family: PingFang SC;font-weight: 600;font-size: 22px;color: #333333;line-height: 0px;padding-left: 553px;">讲师风采</span>
      </div>
      <div class="intro-wrapper" style="padding-top: 3px;padding-bottom: 42px;max-width: 1200px;">
        <Teacher :teacherList="teacherList" />
      </div>
    </div>
  </div>
</template>

<script>
import { Flicking } from "@egjs/vue-flicking";
import { AutoPlay, Pagination } from "@egjs/flicking-plugins";
import "../assets/css/flicking.min.css";
import "../assets/css/pagination.min.css";
import "animate.css";
import Teacher from "../components/Teacher";
import News from "../components/News";
const plugins = [
  new AutoPlay({ duration: 2000, direction: "NEXT", stopOnHover: false }),
  new Pagination({ type: "bullet" }),
];
export default {
  data() {
    return {
      selected: 'button1', // 默认选中第一个按钮
      plugins,
      maskShow: false,
      registerShow: false,
      loginShow: false,
      forgetShow: false,
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination",
        },
        loop: true,
        autoplay: true,
        initialSlide: 1,
      },
      // 表单
      loginForm: {
        mobile: "",
        password: "",
      }, //登陆表单
      loginRule: {
        mobile: { required: true, message: "请输入手机号", trigger: "blur" },
        password: { required: true, message: "请输入密码", trigger: "blur" },
      }, //登陆表单验证
      resgisterForm: {
        name: "",
        unitName: "",
        mobile: "",
        code: "",
        password: "",
        real_password: "",
      },
      //单位名称
      countDown: 60,
      unitList: [], //查询单位列表
      forgetRule: {
        mobile: { required: true, message: "请输入手机号", trigger: "blur" },
        code: { required: true, message: "请输入验证码", trigger: "blur" },
        password: { required: true, message: "请输入密码", trigger: "blur" },
        real_password: {
          required: true,
          message: "请输入确认密码",
          trigger: "blur",
        },
      },
      forgetForm: {
        mobile: "",
        code: "",
        password: "",
        real_password: "",
      },

      userInfo: null, //用户信息,判断是否显示登陆
      bannerList: [],
      courseType: {}, //课程分类数据,
      courseRecommend: {}, //推荐课程数据
      // 新加的
      teacherList: [],
      // newsList: {},
      currentPage: 1,
      is_vip: "",
      newsList: [],//行业要闻
      tid: 1,
    };
  },

  components: { Flicking, Teacher, News },

  computed: {},
  created() {},
  mounted() {
    this.getTeacherList();
    this.getNewsList();
    this.getIndexData();
  },
  methods: {
    select(button) {
      this.selected = button; // 更新选中的按钮
    },
    // 首页数据
    getIndexData() {
      this.axios.post("/").then((res) => {
        this.is_vip = res.data.is_vip;
        this.userInfo = res.data.user_info;
        this.courseType = res.data.course_type.splice(0, 5);
        this.courseRecommend = res.data.recommend_course;
        document.title = res.data.business.name;
        res.data.banner_list.forEach((ele) => {
          if (ele.is_mobile == 0) {
            this.bannerList.push(ele);
          }
        });
        if (
          res.data.user_info &&
          typeof this.$store.state.empInfo !== "Object"
        ) {
          this.$store.dispatch(
            "SET_EMPLOYEE_INFO",
            JSON.stringify(res.data.user_info)
          );
        }
        if (JSON.stringify(this.$store.state.business) === "{}") {
          this.$store.dispatch(
            "SET_BUSINESS_INFO",
            JSON.stringify(res.data.business)
          );
        }
      });
    },

    async getTeacherList() {
      let { data } = await this.axios.get(`/index/teacher_list`);
      this.teacherList = data.list;
    },
    changeList(e) {
      // if (e.target.tagName !== "SPAN") return;
      this.barTitle = e.target.innerText;
      let tid = e.target.dataset.tid;
      this.tid = tid;
      this.getNewsList(this.currentPage, this.tid);
    },
    async getNewsList(page=1,tid=1) {
      if(tid == 1){
        let { data } = await this.axios.get(`/index/news_list?page=${page}`);
        this.newsList = data.list;
        this.currentPage = page;
      }else{
        let { data } = await this.axios.get(`/index/notice_list?page=${page}`);
        this.newsList = data.list;
        this.currentPage = page;
      }
      
    },
    pageChange(page) {
      this.getNewsList(page);
    },
    goCourseDetail(recommend) {
      if (this.is_vip == 0) {
        this.$message.error("请先开通会员才能看此课程!");
      } else {
        this.$router.push(
          `/courdetails?cid=${recommend.id}&ct=${recommend.title}&tn=${recommend.type_name}&tid=${recommend.type_id}`
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.carousel-login {
  position: relative;

  .swiper {
    width: 100%;
    height: 360px;
    div {
      position: relative;
      width: 100%;
      
      background-size: cover;
      background-position: center;
    }
  }
  .login-window {
    width: 240px;
    height: 310px;
    background: #ffffff;
    border-radius: 8px;
    position: absolute;
    top: 50%;
    right: 21%;
    transform: translateY(-50%);
    z-index: 999;
    text-align: center;
    .no-login,
    .logined {
      .logined-img {
        width: 63px;
        height: 63px;
        border-radius: 50%;
        overflow: hidden;
        margin: 40px auto 24px;
        img {
          border-radius: 50%;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      p {
        margin: 0;
        font-size: 14px;
        color: #aca9a9;
      }
      button {
        width: 178px;
        height: 35px;
        background: #8bb9ff;
        opacity: 1;
        border-radius: 22px;
        color: #ffffff;
        font-size: 14px;
        border: 0;
        cursor: pointer;
        margin-top: 54px;
        margin-bottom: 11px;
      }
      span {
        display: flex;
        align-content: center;
        justify-content: center;
        font-size: 14px;
        color: #333333;
      }
      .logined-company {
        margin-top: 11px;
      }
    }
  }
}
.category-recommend {
  // max-width: 1200px;
  // margin: 27px auto 0;
  ul{
    cursor: pointer;
    list-style-position: inside;
    padding-left: 4px; /* 增加左内边距以移动点 */
    padding-top: 10px;
  }
  ul li::marker{
    color: #999999;
    opacity: 0.3;
    font-size: 15px; 
    }
  ul li{
    margin-bottom:20px;
    text-align: left; /* 文本对齐方式 */
    .title {
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 16px;
      color: #333333;
      display: inline-block;
      width: 546px; /* 设置元素的宽度 */
      white-space: nowrap; /* 保证文本在一行内显示 */
      overflow: hidden; /* 超出的部分隐藏 */
      position: absolute; /* 使文本位置固定 */
      text-overflow: ellipsis; /* 使用省略号表示超出的文本 */
    }


    .time{
      float: right;
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 16px;
      color: #666666;
    }
  }
  
  .course-title {
    font-family: PingFang SC;
    font-weight: 600;
    font-size: 22px;
    // color: #4268BB;
    color: #333333 ;
    line-height: 0px;
    cursor: pointer;
  }
  .category-course {
    .active span{
        color: #4268BB ;
      }
    & > a {
      font-size: 18px;
      float: right;
    }
    .category-box {
      padding: 24px 6px;
      box-sizing: border-box;
      .course-item {
        display: inline-block;
        width: 220px;
        height: 130px;
        margin-right: 22px;
        position: relative;
        cursor: pointer;
        border-radius: 8px;
        img {
          width: 100%;
          height: 100%;
          // object-fit: cover;
        }
        &:last-child {
          margin-right: 0;
        }
        b {
          position: absolute;
          font-size: 26px;
          color: #609bf6;
          left: 10%;
          top: 50%;
          transform: translateY(-50%);
          // cursor: default;
        }
      }
    }
  }
  .recommend-course {
    & > a {
      font-size: 18px;
      float: right;
    }
    .recommend-box {
      padding: 24px 0;
      box-sizing: border-box;
      .recommend-item {
        background-color: #FFFFFF;
        display: inline-block;
        width: 283px;
        height: 236px;
        margin-right: 17px;
        border-radius: 5px;    
        margin-bottom: 25px;
        overflow: hidden;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
        .recommend-item-img {
          height: 159px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .recommend-item-text {
          font-family: PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #333333;
          line-height: 24px;
          padding-left: 11px;
          margin-top: 13px;
          overflow: hidden;
          &::after {
            content: "";
            display: block;
            clear: both;
          }
          .text-title {
            float: left;
          }
          .text-time {
            float: right;
          }
        }
      }
    }
  }
}
.teacher-intro,
.news-infos {
  box-sizing: border-box;
  background-color: #FFFFFF;
  .course-title {
    font-size: 18px;
    color: #333333;
    position: relative;
    left: -20px;
  }
  .intro-wrapper,
  .news-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    .el-pagination {
      text-align: center;
      margin-top: 36px;
    }
  }
}
.mask {
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.39);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1111;
  .register {
    max-width: 680px;
    max-height: 647px;
    background: #ffffff;
    border: 1px solid #707070;
    border-radius: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 1% 8%;
    box-sizing: border-box;
    span {
      display: inline-block;
      margin-top: 6%;
      color: #1890ff;
      font-size: 29px;
      position: relative;
      &::after {
        content: "";
        display: block;
        width: 46px;
        height: 3px;
        background: #1890ff;
        position: absolute;
        left: 50%;
        bottom: -5px;
        transform: translateX(-50%);
      }
    }
    i {
      position: absolute;
      right: 3%;
      font-size: 24px;
      cursor: pointer;
    }
    form {
      width: 350px;
      max-height: 500px;
      margin: 10% auto;
      label {
        display: block;
        width: 350px;
        height: 40px;
        background: #ffffff;
        border: 1px solid rgba(217, 217, 217, 0.65);
        border-radius: 20px;
        padding: 6px 5px;
        box-sizing: border-box;
        margin-bottom: 24px;
        background: url("../assets/images/sprite.png") no-repeat;
        position: relative;
        &::before {
          content: "*";
          color: red;
          position: absolute;
          top: 50%;
          left: -6%;
          transform: translateY(-50%);
        }
        input {
          width: 80%;
          height: 75%;
          border: 0;
          outline: 0;
          font-size: 16px;
          &::placeholder {
            color: rgba($color: #000000, $alpha: 0.3);
          }
        }

        b {
          font-weight: normal;
          color: #f56c6c;
          font-size: 12px;
          position: absolute;
          left: 0;
          bottom: -50%;
          opacity: 0;
          transition: all 0.5s;
        }
      }
      button.now-register {
        width: 239px;
        height: 40px;
        background: #8bb9fc;
        border-radius: 20px;
        font-size: 15px;
        color: #ffffff;
        border: 0;
        margin-top: 12px;
        cursor: pointer;
      }
      .label-name-icon {
        background-position: 4% -3%;
      }
      .label-unit-icon {
        background-position: 4% 18%;
      }
      .label-phone-icon {
        background-position: 4% 39%;
      }
      .label-code-icon {
        position: relative;
        background-position: 4% 61%;
        button.send-code-btn {
          height: 100%;
          border: 0;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          font-size: 12px;
          padding: 10px;
          box-sizing: border-box;
          border-radius: 50px;
          color: #000;
          cursor: pointer;
        }
        .send-disable {
          cursor: not-allowed !important;

          background-color: #e7e8e9;
        }
      }
      .label-password-icon {
        background-position: 4% 82%;
      }
    }
  }
  .login-mask {
    width: 25%;
    background: #ffffff;
    border: 1px solid #707070;
    border-radius: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 20px 50px;
    box-sizing: border-box;
    span {
      display: inline-block;
      margin-top: 6%;
      color: #1890ff;
      font-size: 29px;
      position: relative;
      margin-bottom: 47px;
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 3px;
        background: #1890ff;
        position: absolute;
        left: 50%;
        bottom: -5px;
        transform: translateX(-50%);
      }
    }
    i {
      position: absolute;
      right: 3%;
      font-size: 24px;
      cursor: pointer;
    }
  }
  .forget-mask {
    width: 25%;
    background: #ffffff;
    border: 1px solid #707070;
    border-radius: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 20px 50px;
    box-sizing: border-box;
    span {
      display: inline-block;
      margin-top: 6%;
      color: #1890ff;
      font-size: 29px;
      position: relative;
      margin-bottom: 47px;
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 3px;
        background: #1890ff;
        position: absolute;
        left: 50%;
        bottom: -5px;
        transform: translateX(-50%);
      }
    }
    i {
      position: absolute;
      right: 3%;
      font-size: 24px;
      cursor: pointer;
    }
    .msg-box {
      display: flex;
      button {
        width: 40%;
        border: 0;
        background-color: rgba($color: #8bb9fc, $alpha: 0.8);
        border-radius: 50px;
        cursor: pointer;
        color: #fff;

        &:hover {
          background-color: rgba($color: #8bb9fc, $alpha: 0.6);
        }
      }
    }
  }
}
.tech-support {
  padding: 20px;
  text-align: center;
  font-size: 14px;
  color: #b0b0b0;
  background: #f1f1f2;
  cursor: pointer;
  span {
    cursor: default;
  }
}
</style>
<style lang="scss">
:root {
  --animate-duration: 0.3s;
}
.mask {
  .login-mask,
  .forget-mask {
    .el-input__inner {
      border-radius: 50px;
    }
    .el-button {
      border-radius: 50px;
      background-color: #8bb9fc;
      border: 0;
      color: #fff;
    }
  }
}
.register {
  .el-autocomplete {
    position: absolute;
    top: 13%;
    left: 10%;
    height: 110%;
    .el-input {
      height: 68%;
    }
    input {
      width: 120%;
      height: 100%;
      border: 0;
      outline: 0;
      font-size: 16px;
      padding: 0;
      padding-left: 5px;
      &::placeholder {
        color: rgba($color: #000000, $alpha: 0.3);
      }
    }
  }
}
.el-pagination {
  text-align: center;
  margin-top: 30px;
  .btn-prev,
  .btn-next {
    background-color: #fff !important;
    border: 1px solid #66a4ff;
    border-radius: 3px !important;
    padding: 5px 10px !important;
    box-sizing: border-box !important;
    &:hover {
      color: #66a4ff;
    }
    span {
      line-height: 1;
    }
  }
  .el-pager {
    li {
      font-weight: normal;
    }
  }
}
</style>
