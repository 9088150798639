<template>
  <ul class="news-list">
    <li
      class="list-item"
      v-for="news in newsList"
      :key="news.id"
      @click="$router.push(`/details?nid=${news.id}`)"
    >
      <div class="dates">
        <span class="month">{{
          news.newsdate.slice(5).replace(/-/g, ".")
        }}</span>
        <p class="year">{{ news.newsdate.slice(0, 4) }}</p>
      </div>
      <div class="title-content">
        <p class="title">
          {{ news.title }}
        </p>
        <div class="content">
          <div v-html="news.infos"></div>
        </div>
        <div class="content-source">来源：{{ news.source }}</div>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: "News",
  props: {
    newsList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },

  components: {},

  computed: {},

  methods: {},
};
</script>
<style lang="scss" scoped>
.news-list {
  list-style-type: none;
  padding: 0;
  margin-top: 20px;

  p {
    margin: 0;
  }

  .list-item {
    // border-bottom: 1px solid #ddd;
    background-color: #fff;
    border-radius: 3px;
    display: flex;
    padding: 20px;
    // padding-bottom: 0;
    box-sizing: border-box;
    cursor: pointer;
    user-select: none;
    margin-top: 8px;
    &:hover {
      box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 10%);
    }
    .dates {
      .month {
        font-size: 30px;
      }
      .year {
        font-size: 16px;
      }
    }
    .title-content {
      width: 100%;
      margin-left: 40px;
      .title {
        font-size: 16px;
        font-weight: bold;
        height: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre-wrap;
      }
      .content {
        width: 100%;
        height: 60px;
        font-size: 14px;
        position: relative;
        div {
          height: 50px;
          position: absolute;
          top: 30%;
          left: 0;
          -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          display: -webkit-box;
        }
      }
      .content-source {
        font-size: 14px;
        color: #999;
        margin-top: 10px;
      }
    }
  }
}
</style>
